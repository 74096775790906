body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #f0efe9 !important; */
  /* background: #f2f4f5 !important; */
  background: #eaedf1 !important;
  /* background: #f5f7f7 !important; */
}

a {
  text-decoration: none;
  color: rgb(25, 51, 81);
  font-weight: bold;
}

/* 
@font-face {
  font-family: 'Nexa';
  src: local('Nexa'),
    url('./fonts/Fontfabric - Nexa Extra Bold.otf') format('otf');
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slanted {
  position: relative;
  transform-origin: bottom;
  -ms-transform: skew(13deg, 0deg);
  -webkit-transform: skew(13deg, 0deg);
  transform: skew(13deg, 0deg);
  overflow: hidden;

  background-color: #fff;
}
.slanted::before {
  /* background-image: url(https://res.cloudinary.com/dwelloptimal/image/upload/v1654722400/energea/br-images/Mask_group.png); */
  /* background-size: cover;
  background-position: bottom; */
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  -ms-transform: skew(-13deg, 0deg);
  -webkit-transform: skew(-13deg, 0deg);
  transform: skew(-13deg, 0deg);
  transform-origin: bottom;
}
.project-image {
  -webkit-transition: background-image 1.5s linear;
  -moz-transition: background-image 1.5s linear;
  -o-transition: background-image 1.5s linear;
  -ms-transition: background-image 1.5s linear;
  transition: background-image 1.5s linear;
}
